<template>
    <div class="team">
        <main>
            <div class="overlay"></div>
            <img src="https://cms.aplesports.com/storage/uploads/2020/05/06/5eb29cba6d8d01920x400_2.png">
            <div class="container">
                <div class="title main">our team</div>
            </div>
        </main>

        <section class="about-us">
           <div class="container">
                <div class="title">About Us</div>
                <div class="content">
                    <div class="logo">
                        <img src="https://cms.aplesports.com/storage/uploads/2020/04/23/5ea178d6570e1APL_New.png" alt="APL Logo">
                    </div>
                    <div class="paragraphs">
                        <p>
                            APL Esports is an independent tournament organiser that combines the experience of various community figures in Asia to produce premier tournaments. Our goal is to bring the best of Rocket League to the Asian region.
                        </p>
                        <p>
                            We are dedicated towards bringing the best to Asia by providing them with regular tournaments and incentives to play for with quality production.
                        </p>
                    </div>
                </div>
           </div> 
        </section>

        <section class="history">
            <div class="container">
                <div class="title">history</div>
                <div class="content">
                    <div class="paragraphs">
                        <p>
                            After two iterations of the region's largest event, APL Esports was founded to carry on the torch and successfully ran the Asia Pro League's third season.
                        </p>
                        <p>
                            Since then, we've been focused on tournaments run by the community, for the community. Having run several iterations of both The Clash and the Ketteisen Cup, our events have consistently attracted Asia's top teams and fans alike. Even though it's been more than a year since APL Season 3, our goal remains the same - to deliver the region's best teams to the world's biggest stages.
                        </p>
                    </div>
                    <div class="logo">
                        <img src="https://cms.aplesports.com/storage/uploads/2020/04/23/5ea1cb9613cddapl_old.png" alt="Old APL Logo">
                    </div>
                </div>
            </div>
        </section>

        <section class="members">
            <div class="container">
                <div class="title">our members</div>

                <div class="grid">
                    <div class="team-panel" v-for="member in members" :key="member.id">
                        <div class="pfp">
                            <img :src="member.dp" :alt="member.name">
                        </div>
                        <div class="name">
                            <div class="discord">{{ member.discord }}</div>
                            <div class="name">{{ member.name }}</div>
                        </div>
                        <div class="roles">
                            <span class="role" v-for="role in member.role" :key="role">
                                {{ role }}
                            </span>
                        </div>
                        <div class="socials">
                            <div class="social twitter" v-if="member.twitter">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z"/>
                                </svg>
                                <a target="_blank" :href="'https://twitter.com/' + member.twitter">{{ member.twitter }}</a>
                            </div>
                            <div class="social twitch" v-if="member.twitch">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M10.224 17.806l1.776-1.776h3.343l2.09-2.09v-6.686h-10.03v8.776h2.821v1.776zm3.866-8.149h1.254v3.653h-1.254v-3.653zm-3.344 0h1.254v3.653h-1.254v-3.653zm1.254-9.657c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.687 14.567l-3.657 3.657h-2.716l-1.777 1.776h-1.88v-1.776h-3.344v-9.821l.941-2.403h12.433v8.567z"/>
                                </svg>
                                <a target="_blank" :href="'https://twitch.tv/' + member.twitch">{{ member.twitch }}</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Team',
    data() {
        return {
            members: {}
        }
    },
    created() {
        axios.post("https://cms.aplesports.com/api/collections/get/Members", {}, {
            headers: {
                'Cockpit-Token': process.env.VUE_APP_CMS_TOKEN
            }
        }).then((res) => {
            this.members = res.data.entries;
        });
    }
}
</script>
